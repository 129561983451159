import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'
import moment from 'moment'
import siteinfo from '@/util/global.js'
class Goods{

    /**
     * 获取商品分类信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getCategroyList(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if(extra.shop_id ) param.shop_id = extra.shop_id
            if(extra.key ) param.key = extra.key
        }
        $post("getGoodsCategoryList",param).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 添加分类
     * @param {object} data 
     * @param {function} fn 
     * @returns 
     */
    addOrEditCategory(data,fn){
        if(!data.name ){
            tool.message("请输入分类名称","warning")
            return
        }
        if( !data.id ) delete data.id
        $post("createOrUpdateGoodsCategory",data).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        }).catch(()=>{})
    }

    /**
     * 获取商品列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getGoodsList(page,limit,extra=null,fn){
        let param = { page,limit } , api = 'getGoodsList'
        if( extra ){
            if( extra.sort ) param.sort = extra.sort
            if( extra.key ) param.key = extra.key
            if( extra.is_purchase ) param.is_purchase = extra.is_purchase
            if( extra.active==1 ) param.is_put_away = 1
            if( extra.active==2 ) param.is_put_away = 0
            if( extra.active==3 ) param.is_recommend = 1
            if( extra.active==4 ) param.is_recommend = 2
            if( extra.audit ) param.audit = extra.audit
            if( extra.shop_id ) {
                param.shop_id = extra.shop_id
                api = 'getShopGoodsList'
            }
        }
        common.getDataList(api,param).then(res=>{
            fn(res)
        })
    }

    /**
     * 添加、编辑商品信息
     * @param {object} goods 商品基本信息
     * @param {object} spec  商品规格信息
     * @param {object} goodsSpec 商品规格信息
     * @param {function} fn 
     */
    addOrEditGoods(goods,spec,goodsSpec,fn){
        if( !goods.id ) delete goods.id
        $post("createOrUpdateGoods",{goods,spec,goodsSpec}).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }
    
    /**
     * 更新商品上下架状态
     * @param {number} id 
     * @param {number} value 
     * @param {number} type 
     * @param {function} fn 
     * @returns 
     */
    changeGoodsStatus(id,value,type,fn){
        if( !id || id.length == 0 ){
            tool.message("请选择要操作的商品","warning")
            return
        }
        $post('changeSomething',{pk:id,type,value }).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        })
    }

    /**
     * 获取商品服务
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getGoodsServer(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.shop_id ) param.shop_id = shop_id
        } 
        common.getDataList("getGoodsServerList",param).then(res=>fn(res))
    }
    /**
     * 新增或编辑商品服务信息
     * @param {object} param 
     * @param {function} fn 
     * @returns 
     */
    createOrEditGoodsServer(param,fn){
        if( !tool.returnMessage(param.name,'请输入服务名称')) return
        if( !param.id ) delete param.id
        $post("createOrUpdateGoodsService",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 添加规格项
     * @param {string} name 
     * @param {function} fn 
     */
    addSpecItem(name,fn) {
        if( !tool.returnMessage(name,'请输入规格项')) return
        $post('createSpecOrSpecValue',{name}).then(res=>{
            fn({
                spec_id: res.data.id,
                name: name,
                spec_value: []
            })
        },()=>{})
    }

    /**
     * 添加规格值
     * @param {number} id 
     * @param {string} name 
     * @param {function} fn 
     * @returns 
     */
    addSpecValue(id,name,fn){
        if( !tool.returnMessage(name,'请输入规格值')) return
        $post('createSpecOrSpecValue',{name,id } ).then(res=>{
            fn({
                id: res.data.id,   //spec_value表主键id
                spec_value: name,
                spec_id: id	
            })
          
        },()=>{})
    }

    /**
     * 获取规格组合
     * @param {object} specData 
     * @param {function} fn 
     */
    getSpecSku(specData,fn){
        $post('getSpecCombination',specData).then(res=>{
            fn(res.data || [])
        },()=>{})
    }

    /**
     * 获取商品详情
     * @param {number} id 
     * @param {function} fn 
     */
    getGoodsDetail(id,fn){
        $post('getGoodsDetail',{id}).then(res=>{
            fn(res.data)
        })
    }
    
    /**
     * 订单批量发货
     * @param {string} url 
     */
    sendOrderBatch(url){
        $post('ShipByShopOrder',{file_url:url}).then(res=>{
            tool.message('批量发货成功')
        }).catch(()=>{})
    }

    /**
     * 获取订单列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.outlet_id ) param.outlet_id = extra.outlet_id
            if( extra.recovery_method ) param.recovery_method = extra.recovery_method
            if( extra.order_status==0 || extra.order_status==1 || extra.order_status == 2 || extra.order_status == 3 ){
                param.order_status = extra.order_status
            }
            if( extra.order_status == -1 )param.is_recycle = 0
            if( extra.order_status == 4 ) param.is_refund = 2
            if( extra.order_status == 5 ) param.is_cancel = 2
            if( extra.order_status == 6 ) param.is_recycle = 1
            if( extra.order_status == 7 ) param.is_refund = 1
            
            if( extra.searchTime && extra.searchTime.length > 0 ){
                param.start_time = moment(extra.searchTime[0]).format("YYYY-MM-DD")
                param.end_time = moment(extra.searchTime[1]).format("YYYY-MM-DD")
            }
            
        }
        common.getDataList("getOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 订单操作
     * @param {number} id 
     * @param {number} type    cancel取消订单 refund退款 hexiao核销
     *                         2移出/入回收站  、3确认收货  6删除订单 
     * @param {string} title 
     * @param {function} fn 
     */
    handleOrder(id,type,title,fn){
        tool.confirm(title).then(()=>{

            //取消订单
            if( type == 'cancel'){
                $post("cancelOrder",{ pk:id,order_type:1  }).then(res=>{
					tool.message("操作成功")
					fn(res.data)
				})
                return
            }
            //2移出/入回收站  3确认收货  6删除订单 
            if( ['2','3','6',2,3,6].includes(type)){
                let param = { pk:id,type,order_type:1}
               
                
                $post("doSomethingByOrder",param).then(res=>{
                    tool.message("操作成功")
                    fn(res.data)
                })
                return
            }

            //退款
            if( type == 'refund'){
                $post('agreeShopRefund',{ pk:id} ).then(res=>{
                    tool.message("操作成功")
                    fn(res.data)
                })
            }
            //核销
            if( type == 'hexiao'){
                $post('writeOff',{ code:id }).then(res=>{
                    tool.message("操作成功")
                    fn(res.data)
				})
            }
            
        }).catch(()=>{})
    }

    /**
     * 退款拒绝
     * @param {number} id 
     * @param {string} reason 
     * @param {function} fn 
     * @returns 
     */
    orderRefuseRefund(id,reason,fn){
        if( !tool.returnMessage(reason,'请填写退款拒绝理由')) return
        $post('refuseRefund',{pk:id,reason}).then(res=>{
            tool.message('操作成功')
            fn(res.data)
        })
    }

    /**
     * 修改订单价格
     * @param {number} id 
     * @param {number} price 
     * @param {function} fn 
     */
    updateOrderPrice(id,price,fn){
        let param = { manager_discount:price,pk:id,type:1,order_type:1}
        $post("doSomethingByOrder",param).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        })
    }
    exportOrder(search,type,param=null){
        let url = ""
        if( type=='outlet' ){
            if( !tool.returnMessage(search.outlet_id,'请先选择门店')) return
            url =siteinfo.siteinfo.siteroot+'/api/admin/outputOutletOrder?token='+localStorage.getItem('token');
        }else{
            if( !tool.returnMessage(param,'请选择要导出的信息')) return
            url = siteinfo.siteinfo.siteroot+'/api/admin/outPutOrderExcel?token='+localStorage.getItem('token')
            url+='&fields='+param.join(',') //要导出字段
        }
        if( search.key ) url+= `&key=${search.key}`
        if( search.outlet_id ) url+= `&outlet_id=${search.outlet_id}`
        if( search.recovery_method ) url+= `&recovery_method=${search.recovery_method}`
        if( search.order_status==0 || search.order_status==1 || search.order_status == 2 || search.order_status == 3 ){
            url+= `&order_status=${search.order_status}`
        }
        if(search.order_status == 4 ) url+= '&is_refund=2'
        if(search.order_status == 5 ) url+= '&is_cancel=1'
        if(search.order_status == 6 ) url+= '&is_recycle=1'
        if(search.order_status == 7 ) url+= '&is_refund=1'

        if( search.searchTime && search.searchTime.length > 0 ){
            url+='&start_time='+moment(search.searchTime[0]).format("YYYY-MM-DD")
			url+='&end_time='+moment(search.searchTime[1]).format("YYYY-MM-DD")
        }
		window.open(url);
    }

    /**
     * 获取订单详情
     * @param {number} id 
     * @param {function} fn 
     */
    getOrderDetail(id,fn){
        $post('getShopOrderDetail',{pk:id}).then(res=>{
           fn(res.data)
        })
    }

    /**
     * 发货时获取订单详情
     * @param {number} id 
     * @param {number} order_type 
     * @param {function} callback 
     */
    getProOrderDetail(id,order_type,callback){
        let param = { id,order_type }
        $post('getProOrderDetailes',param).then(res=>callback(res.data))
    }

    /**
     * 获取售后订单列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getSaleOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.status ) param.status = extra.status
        }
        common.getDataList("getSaleList",param).then(res=>fn(res))
    }


    /**
     * 售后订单处理
     * @param {number} id 
     * @param {number} type 1同意 2拒绝 
     * @param {string} msg 
     * @param {function} fn 
     * @returns 
     */
    dealSaleOrder(id,type,msg,fn){
        //拒绝退款需要填写说明
        if( type == '2'){
            if( !tool.returnMessage(msg,'请填写处理说明')) return
        }
        let param = { id,type,msg}
        $post('saleDealWith',param).then(res=>{
            tool.message("操作成功")
            fn(res.data)
        })
    }

    /**
     * 修改订单收货地址
     * @param {object} param 
     * @param {function} cb 
     */
    updateOrderAddress(param,callback){
        if( !tool.returnMessage(param.name,"请输入收货人姓名")) return
        if( !tool.returnMessage(param.phone,"请输入联系电话")) return
        if( !tool.returnMessage(param.address,"请选择收货地址")) return
        if( !param.lat || !param.lng ){
            tool.message("请重新选择收货地址","warning")
            return
        }
        
        $post('updateAddressByOrder',param).then(res=>{
            tool.message('操作成功')
            callback(res.data)
        })
    }

    /**
     * 修改物流信息
     * @param {object} param 
     * @param {function} callback 
     * @returns 
     */
    updateLogistics(param,callback){
        if( !tool.returnMessage(param.express_company,'请选择快递公司')) return
        if( !tool.returnMessage(param.express_no,'请输入快递单号')) return
        $post('updateLogisticsInfor',param).then(res=>{
            tool.message("修改成功")
            callback(res.data)
        })
    }

    /**
     * 达达配送
     * @param {object}} param 
     * @param {function} callback 
     * @returns 
     */
    deliveryByDada(param,callback){
        if( !tool.returnMessage(param.date ,'请选择取件预约日期！') ) return
        if( !tool.returnMessage(param.time ,'请选择取件预约时间！') ) return
        if( !tool.returnMessage(param.outlet_id ,'请选择配送门店') ) return
        let data = {
            id:param.id,
            order_type:param.type,
            send_type:'dada',
            delay_publish_time:moment(param.date).format('YYYY-MM-DD')+' '+moment(param.time).format('HH:mm'),
            tips:param.tips,
            outlet_id:param.outlet_id
        }
        if( param.reback ){	//重新创建达达配送订单
            $post('reAddOrder',data).then(res=>{
                tool.message('预约成功，请耐心等待取件！')
                callback(res.data)
            })
        }else{
            $post('pushOrder',data).then(res=>{
                tool.message('预约成功，请耐心等待取件！')
                callback(res.data)
            })
        }
    }

    /**
     * 快递发货
     * @param {object} param 
     * @param {function} callback 
     */
    deliveryByExpress(param,callback){
        if( !param.name || !param.mobile || !param.address ){
            tool.message('收货信息不完整，不能进行发货！',"warning")
            return
        }
        if( !tool.returnMessage(param.express_company,"请选择快递公司")) return
        if( !tool.returnMessage(param.express_no,"请填写快递单号")) return
        $post("doSomethingByOrder",param).then(res=>{
            tool.message('发货成功')
            callback(res.data)
        })
    }

    /**
     * 商家配送
     * @param {object} param 
     * @param {function} callback 
     */
    deliveryByMerchant(param,callback){
        
        
        if( !tool.returnMessage(param.outlet_id,"请选择门店id")) return
        if( !tool.returnMessage(param.persion_id,"请选择配送员")) return

        tool.confirm("确认要将该订单进行发货操作吗？").then(()=>{
            $post("makeDelivery",param).then(res=>{
                tool.message("操作成功")
                callback(res.data)
            })
        }).catch(()=>{})
    }
}

const goodsModel = new Goods()
export default goodsModel